<template>
  <div>
    <loader
      object="#ffffff"
      color1="#ffffff"
      color2="#ffffff"
      time="100"
      size="5"
      speed="2"
      bg="#5e151d"
      objectbg="#999793"
      opacity="100"
      :name="selected"
      disableScrolling="false"
    ></loader>
    <div class="container" style="min-height: 150px">
      <div class="card">
        <div class="card-header">
          <div class="row">
            <div class="col-11">
              <h1><b-icon icon="cash-stack" /> Pagos en {{ desserts.name }}</h1>
            </div>
            <div class="col-1">
              <h1>
                <b-icon
                  type="button"
                  @click="toPrograms"
                  icon="arrow-return-left"
                />
              </h1>
            </div>
          </div>
        </div>
        <div class="card-body">
          <div class="container col-12">
            <b-tabs class="card" card fill>
              <b-tab
                v-for="(item, index) in desserts.list"
                :key="'A' + index"
                :title="item.name"
              >
                <b-row class="mb-3">
                  <b-col lg="6">
                    <ReportPayments
                      :nameExcel="
                        desserts.name + '_' + item.name + '_ReportePagos'
                      "
                      :dataExcel="item.list"
                    />
                  </b-col>
                  <b-col lg="6"> </b-col>
                </b-row>
                <div
                  class="table-responsive"
                  style="transform: rotateX(180deg)"
                >
                  <table
                    class="table table-striped"
                    style="transform: rotateX(180deg)"
                  >
                    <thead>
                      <tr>
                        <th scope="col">#</th>
                        <th scope="col">Nombres</th>
                        <th scope="col">Matrícula</th>
                        <th
                          scope="col"
                          v-for="(i, index) in 9"
                          :key="'B' + index"
                        >
                          {{ "P" + i }}
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr
                        v-for="(value, index) in item.list"
                        :key="'C' + index"
                      >
                        <th scope="row">{{ index + 1 }}</th>
                        <th scope="row">{{ value.name }}</th>
                        <td
                          scope="row"
                          v-for="(data, index) in value.list"
                          :key="'D' + index"
                        >
                          <p
                            class="my-0"
                            v-for="(data2, index) in data.list"
                            :key="'E' + index"
                          >
                            {{ data2.amount }}
                            <b-link
                              target="_blank"
                              :href="
                                $urlFirebase + data2.voucherImage + '?alt=media'
                              "
                              >{{ " (" + data2.operationNumber + ")" }}</b-link
                            >
                          </p>
                          <p v-if="data.list.length == 0">-</p>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </b-tab>
            </b-tabs>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import ReportPayments from "./Payments-Report.vue";
export default {
  components: { ReportPayments },
  props: {
    id: {
      type: String,
      default: null,
    },
    idPro: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    selected: "",
    name: "",
    desserts: {
      list: [],
    },
  }),

  created() {
    this.initialize();
  },

  methods: {
    toPrograms() {
      this.$router.go(-1);
    },
    async initialize() {
      this.selected = "circular";
      await this.$axios
        .get("/academic/" + this.id + "/" + this.idPro + "/pay-control", {
          headers: {
            Authorization: `Bearer ${localStorage.token}`,
          },
        })
        .then((res) => {
          this.desserts = res.data;
          this.selected = "";
        })
        .catch((error) => {
          this.$errorQuery(error);
        });
    },
  },
};
</script>
<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
</style>
<style>
.card-header {
  background-color: rgb(119, 35, 45);
  color: white;
}
.nav-tabs .nav-link.active {
  background-color: rgb(243, 243, 243);
  border-color: rgb(243, 243, 243);
  color: black;
  font-weight: bolder;
}
.nav-tabs .nav-link {
  color: white;
}
</style>