<template>
  <div>
    <vue-excel-xlsx
      class="btn btn-success font-weight-bold w-100"
      :data="dataExcel"
      :columns="columns"
      :file-name="nameExcel"
      :file-type="'xlsx'"
      :sheet-name="'PAGOS'"
    >
      <b-icon-file-earmark-excel /> Reporte de pagos
    </vue-excel-xlsx>
  </div>
</template>

<script>
export default {
  props: {
    nameExcel: {
      type: String,
      default: null,
    },
    dataExcel: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      columns: [
        {
          label: "NOMBRE COMPLETO",
          field: "name",
        },
        {
          label: "MATRICULA",
          field: "list[0].list",
          dataFormat: this.priceFormat,
        },
        {
          label: "P1",
          field: "list[1].list",
          dataFormat: this.priceFormat,
        },
        {
          label: "P2",
          field: "list[2].list",
          dataFormat: this.priceFormat,
        },
        {
          label: "P3",
          field: "list[3].list",
          dataFormat: this.priceFormat,
        },
        {
          label: "P4",
          field: "list[4].list",
          dataFormat: this.priceFormat,
        },
        {
          label: "P5",
          field: "list[5].list",
          dataFormat: this.priceFormat,
        },
        {
          label: "P6",
          field: "list[6].list",
          dataFormat: this.priceFormat,
        },
        {
          label: "P7",
          field: "list[7].list",
          dataFormat: this.priceFormat,
        },
        {
          label: "P8",
          field: "list[8].list",
          dataFormat: this.priceFormat,
        },
        {
          label: "P9",
          field: "list[9].list",
          dataFormat: this.priceFormat,
        },
      ],
    };
  },
  methods: {
    priceFormat(value) {
      if (value.length == 0) return "-";
      else {
        var string = "";
        for (const i in value) {
          string =
            string + value[i].amount + " (" + value[i].operationNumber + ") ";
        }
        return string;
      }
    },
  },
};
</script>